// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import FupPersonListSchema from './fup-person-list-schema';
import ResourceLinkSchema from './resource-link-schema';
import PaginationDataSchema from './pagination-data-schema';

const schema = {
  $id: '/fup-person-list-paging-result-schema',
  type: 'object',
  required: [],
  properties: {
    items: {
      type: 'array',
      nullable: true,
      items: { ...FupPersonListSchema },
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    paginationData: {
      ...PaginationDataSchema,
    },
  },
} as IJSONSchema;

export default schema;
