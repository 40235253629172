// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/contribution-item-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    contributionCategorySelectionId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    contributionCategorySelectionOrganisationId: {
      type: 'string',
      format: 'uuid',
      nullable: true,
    },
    contributionCategorySelection: {
      type: 'string',
      nullable: true,
    },
    contributionCategory_ContributionValue: {
      type: 'number',
      nullable: true,
    },
    contributionCategoryFrom: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    contributionCategoryUntil: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    contributionCategoryIsCurrently: {
      type: 'boolean',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
