// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ContributionModel from '../../model/contribution-model';
import ContributionDTO from '../dto/contribution-dto';
import dtoSchema from '../schemas/contribution-schema';
import ContributionCategoryModel from '../../model/contribution-category-model';
import ContributionItemModel from '../../model/contribution-item-model';

export default abstract class ContributionModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ContributionDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.name = data.name ?? undefined;
      this.genericName = data.genericName ?? undefined;
      this.shortName = data.shortName ?? undefined;
      this.exportName = data.exportName ?? undefined;
      this.orderNr = data.orderNr;
      this.region = data.region ?? undefined;
      this.country = data.country ?? undefined;
      this.address = data.address ?? undefined;
      this.address2 = data.address2 ?? undefined;
      this.zip = data.zip ?? undefined;
      this.city = data.city ?? undefined;
      this.phone = data.phone ?? undefined;
      this.website = data.website ?? undefined;
      this.email = data.email ?? undefined;
      this.district = data.district ?? undefined;
      this.departmentID = data.departmentID;
      this.isDepartment = data.isDepartment;
      this.compensationPeriodBegin = data.compensationPeriodBegin;
      this.compensationPeriodEnd = data.compensationPeriodEnd;
      this.isAssociation = data.isAssociation;
      this.associationName = data.associationName ?? undefined;
      this.associationAddress = data.associationAddress ?? undefined;
      this.associationAddress2 = data.associationAddress2 ?? undefined;
      this.associationZip = data.associationZip ?? undefined;
      this.associationCity = data.associationCity ?? undefined;
      this.associationMail = data.associationMail ?? undefined;
      this.category = data.category ?? undefined;
      this.contributionCategory = data.contributionCategory ?? undefined;
      this.dtaBankname = data.dtaBankname ?? undefined;
      this.dtaIban = data.dtaIban ?? undefined;
      this.dtaBankClearing = data.dtaBankClearing ?? undefined;
      this.dtaPaymentReason = data.dtaPaymentReason ?? undefined;
      this.isLocked = data.isLocked;
      this.messageCredit = data.messageCredit ?? undefined;
      this.instructionsOnLegalRemedies = data.instructionsOnLegalRemedies ?? undefined;
      this.isPublicVisible = data.isPublicVisible;
      this.taxFreeAmountConfederation = data.taxFreeAmountConfederation;
      this.taxFreeAmountCanton = data.taxFreeAmountCanton;
      this.ahv_FreeAmount = data.ahv_FreeAmount;
      this.retirementFundsNumber = data.retirementFundsNumber ?? undefined;
      this.inviteMail_Stab = data.inviteMail_Stab ?? undefined;
      this.inviteMail_Teilnehmer = data.inviteMail_Teilnehmer ?? undefined;
      this.inviteMail_Kdt = data.inviteMail_Kdt ?? undefined;
      this.inviteMailSenderMail = data.inviteMailSenderMail ?? undefined;
      this.inviteMailSenderName = data.inviteMailSenderName ?? undefined;
      this.ahv_MinorWage = data.ahv_MinorWage;
      this.wageStatement_Signature = data.wageStatement_Signature ?? undefined;
      this.ahv_EmployeeCompensation = data.ahv_EmployeeCompensation;
      this.alv_EmployeeCompensation = data.alv_EmployeeCompensation;
      this.language = data.language ?? undefined;
      this.categoryItem = data.categoryItem ? ContributionCategoryModel.toModel(data.categoryItem) : undefined;
      this.items = data.items?.map((i) => ContributionItemModel.toModel(i)) ?? [];
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  name?: string;
  /**
  */
  genericName?: string;
  /**
  */
  shortName?: string;
  /**
  */
  exportName?: string;
  /**
  * @type {int32}
  */
  orderNr?: number;
  /**
  */
  region?: string;
  /**
  */
  country?: string;
  /**
  */
  address?: string;
  /**
  */
  address2?: string;
  /**
  */
  zip?: string;
  /**
  */
  city?: string;
  /**
  */
  phone?: string;
  /**
  */
  website?: string;
  /**
  */
  email?: string;
  /**
  */
  district?: string;
  /**
  * @type {uuid}
  */
  departmentID?: string;
  /**
  */
  isDepartment?: boolean;
  /**
  * @type {date-time}
  */
  compensationPeriodBegin?: string;
  /**
  * @type {date-time}
  */
  compensationPeriodEnd?: string;
  /**
  */
  isAssociation?: boolean;
  /**
  */
  associationName?: string;
  /**
  */
  associationAddress?: string;
  /**
  */
  associationAddress2?: string;
  /**
  */
  associationZip?: string;
  /**
  */
  associationCity?: string;
  /**
  */
  associationMail?: string;
  /**
  */
  category?: string;
  /**
  */
  contributionCategory?: string;
  /**
  */
  dtaBankname?: string;
  /**
  */
  dtaIban?: string;
  /**
  */
  dtaBankClearing?: string;
  /**
  */
  dtaPaymentReason?: string;
  /**
  */
  isLocked?: boolean;
  /**
  * @type {double}
  */
  messageCredit?: number;
  /**
  */
  instructionsOnLegalRemedies?: string;
  /**
  */
  isPublicVisible?: boolean;
  /**
  * @type {double}
  */
  taxFreeAmountConfederation?: number;
  /**
  * @type {double}
  */
  taxFreeAmountCanton?: number;
  /**
  * @type {double}
  */
  ahv_FreeAmount?: number;
  /**
  */
  retirementFundsNumber?: string;
  /**
  */
  inviteMail_Stab?: string;
  /**
  */
  inviteMail_Teilnehmer?: string;
  /**
  */
  inviteMail_Kdt?: string;
  /**
  */
  inviteMailSenderMail?: string;
  /**
  */
  inviteMailSenderName?: string;
  /**
  * @type {double}
  */
  ahv_MinorWage?: number;
  /**
  */
  wageStatement_Signature?: string;
  /**
  * @type {double}
  */
  ahv_EmployeeCompensation?: number;
  /**
  * @type {double}
  */
  alv_EmployeeCompensation?: number;
  /**
  */
  language?: string;
  /**
  */
  categoryItem?: ContributionCategoryModel;
  /**
  */
  items?: ContributionItemModel[];

  static toModel(dto: DeepPartial<ContributionDTO>): ContributionModel;
  static toModel(dto: DeepPartial<ContributionDTO> | undefined | null): ContributionModel | undefined;
  static toModel(dto: DeepPartial<ContributionDTO> | undefined | null): ContributionModel | undefined {
    return dto ? new ContributionModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ContributionModel> | ContributionModel): ContributionDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      name: unwrapped.name,
      genericName: unwrapped.genericName,
      shortName: unwrapped.shortName,
      exportName: unwrapped.exportName,
      orderNr: unwrapped.orderNr,
      region: unwrapped.region,
      country: unwrapped.country,
      address: unwrapped.address,
      address2: unwrapped.address2,
      zip: unwrapped.zip,
      city: unwrapped.city,
      phone: unwrapped.phone,
      website: unwrapped.website,
      email: unwrapped.email,
      district: unwrapped.district,
      departmentID: unwrapped.departmentID,
      isDepartment: unwrapped.isDepartment,
      compensationPeriodBegin: unwrapped.compensationPeriodBegin,
      compensationPeriodEnd: unwrapped.compensationPeriodEnd,
      isAssociation: unwrapped.isAssociation,
      associationName: unwrapped.associationName,
      associationAddress: unwrapped.associationAddress,
      associationAddress2: unwrapped.associationAddress2,
      associationZip: unwrapped.associationZip,
      associationCity: unwrapped.associationCity,
      associationMail: unwrapped.associationMail,
      category: unwrapped.category,
      contributionCategory: unwrapped.contributionCategory,
      dtaBankname: unwrapped.dtaBankname,
      dtaIban: unwrapped.dtaIban,
      dtaBankClearing: unwrapped.dtaBankClearing,
      dtaPaymentReason: unwrapped.dtaPaymentReason,
      isLocked: unwrapped.isLocked,
      messageCredit: unwrapped.messageCredit,
      instructionsOnLegalRemedies: unwrapped.instructionsOnLegalRemedies,
      isPublicVisible: unwrapped.isPublicVisible,
      taxFreeAmountConfederation: unwrapped.taxFreeAmountConfederation,
      taxFreeAmountCanton: unwrapped.taxFreeAmountCanton,
      ahv_FreeAmount: unwrapped.ahv_FreeAmount,
      retirementFundsNumber: unwrapped.retirementFundsNumber,
      inviteMail_Stab: unwrapped.inviteMail_Stab,
      inviteMail_Teilnehmer: unwrapped.inviteMail_Teilnehmer,
      inviteMail_Kdt: unwrapped.inviteMail_Kdt,
      inviteMailSenderMail: unwrapped.inviteMailSenderMail,
      inviteMailSenderName: unwrapped.inviteMailSenderName,
      ahv_MinorWage: unwrapped.ahv_MinorWage,
      wageStatement_Signature: unwrapped.wageStatement_Signature,
      ahv_EmployeeCompensation: unwrapped.ahv_EmployeeCompensation,
      alv_EmployeeCompensation: unwrapped.alv_EmployeeCompensation,
      language: unwrapped.language,
      categoryItem: unwrapped.categoryItem,
      items: unwrapped.items,
    } as ContributionDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ContributionModel, can not map to ContributionDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
