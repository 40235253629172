// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetFupPersonsRequestModel from '../../model/get-fup-persons-request-model';
import GetFupPersonsRequestDTO from '../dto/get-fup-persons-request-dto';
import dtoSchema from '../schemas/get-fup-persons-request-schema';

export default abstract class GetFupPersonsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetFupPersonsRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.organisationId = data.organisationId;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {uuid}
  */
  organisationId?: string;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetFupPersonsRequestDTO>): GetFupPersonsRequestModel;
  static toModel(dto: DeepPartial<GetFupPersonsRequestDTO> | undefined | null): GetFupPersonsRequestModel | undefined;
  static toModel(dto: DeepPartial<GetFupPersonsRequestDTO> | undefined | null): GetFupPersonsRequestModel | undefined {
    return dto ? new GetFupPersonsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetFupPersonsRequestModel> | GetFupPersonsRequestModel): GetFupPersonsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      organisationId: unwrapped.organisationId,
      searchCriteria: unwrapped.searchCriteria,
    } as GetFupPersonsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetFupPersonsRequestModel, can not map to GetFupPersonsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
