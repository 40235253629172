import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
  ],
};
