// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import FupUserListModel from '../../model/fup-user-list-model';
import FupUserListDTO from '../dto/fup-user-list-dto';
import dtoSchema from '../schemas/fup-user-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class FupUserListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<FupUserListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.organisationId = data.organisationId ?? undefined;
      this.organisationGenericName = data.organisationGenericName ?? undefined;
      this.email = data.email ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.firstName = data.firstName ?? undefined;
      this.userName = data.userName ?? undefined;
      this.lastLoginDate = data.lastLoginDate ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {uuid}
  */
  organisationId?: string;
  /**
  */
  organisationGenericName?: string;
  /**
  */
  email?: string;
  /**
  */
  lastName?: string;
  /**
  */
  firstName?: string;
  /**
  */
  userName?: string;
  /**
  * @type {date-time}
  */
  lastLoginDate?: string;

  static toModel(dto: DeepPartial<FupUserListDTO>): FupUserListModel;
  static toModel(dto: DeepPartial<FupUserListDTO> | undefined | null): FupUserListModel | undefined;
  static toModel(dto: DeepPartial<FupUserListDTO> | undefined | null): FupUserListModel | undefined {
    return dto ? new FupUserListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<FupUserListModel> | FupUserListModel): FupUserListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      organisationId: unwrapped.organisationId,
      organisationGenericName: unwrapped.organisationGenericName,
      email: unwrapped.email,
      lastName: unwrapped.lastName,
      firstName: unwrapped.firstName,
      userName: unwrapped.userName,
      lastLoginDate: unwrapped.lastLoginDate,
    } as FupUserListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for FupUserListModel, can not map to FupUserListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
