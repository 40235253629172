// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetWebAppointmentListRequestModel from '../../model/get-web-appointment-list-request-model';
import GetWebMembersOperationsRequestModel from '../../model/get-web-members-operations-request-model';
import GetWebMembersOperationByIdRequestModel from '../../model/get-web-members-operation-by-id-request-model';
import GetOrganisationListRequestModel from '../../model/get-organisation-list-request-model';
import GetOrganisationByIdRequestModel from '../../model/get-organisation-by-id-request-model';
import GetWebOrganisationLogoRequestModel from '../../model/get-web-organisation-logo-request-model';
import WebAppointmentListListResultModel from '../../model/web-appointment-list-list-result-model';
import WebOperationListListResultModel from '../../model/web-operation-list-list-result-model';
import WebOperationModel from '../../model/web-operation-model';
import WebOrganisationListResultModel from '../../model/web-organisation-list-result-model';
import WebOrganisationModel from '../../model/web-organisation-model';

export default (service: Servicelayer) => ({
  /**  */
  async getWebAppointmentList(request: GetWebAppointmentListRequestModel, config?: RequestConfig) {
    const requestDTO = GetWebAppointmentListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-web-appointment-list'] ?? 'web/Organisations/{organisationId}/Appointments/Appointments';
    endpointPath = endpointPath.replace(/\{organisationId\}/g, `${requestDTO.organisationId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        year: requestDTO.year,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WebAppointmentListListResultModel.toModel(dto));
  },
  /**  */
  async getWebMembersOperations(request: GetWebMembersOperationsRequestModel, config?: RequestConfig) {
    const requestDTO = GetWebMembersOperationsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-web-members-operations'] ?? 'web/Organisations/{organisationId}/Operations/Operations';
    endpointPath = endpointPath.replace(/\{organisationId\}/g, `${requestDTO.organisationId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        year: requestDTO.year,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WebOperationListListResultModel.toModel(dto));
  },
  /**  */
  async getWebMembersOperationById(request: GetWebMembersOperationByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetWebMembersOperationByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-web-members-operation-by-id'] ?? 'web/Organisations/{organisationId}/Operations/Operations/{operationId}';
    endpointPath = endpointPath.replace(/\{organisationId\}/g, `${requestDTO.organisationId}`);
    endpointPath = endpointPath.replace(/\{operationId\}/g, `${requestDTO.operationId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WebOperationModel.toModel(dto));
  },
  /**  */
  async getOrganisationList(request: GetOrganisationListRequestModel, config?: RequestConfig) {
    const requestDTO = GetOrganisationListRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-organisation-list'] ?? 'web/Organisations';
    const response = await service.get<any>(endpointPath, {
      query: {
        organisationName: requestDTO.organisationName,
        region: requestDTO.region,
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WebOrganisationListResultModel.toModel(dto));
  },
  /**  */
  async getOrganisationById(request: GetOrganisationByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetOrganisationByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-organisation-by-id'] ?? 'web/Organisations/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(WebOrganisationModel.toModel(dto));
  },
  /**  */
  async getWebOrganisationLogo(request: GetWebOrganisationLogoRequestModel, config?: RequestConfig) {
    const requestDTO = GetWebOrganisationLogoRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-web-organisation-logo'] ?? 'web/Organisations/{id}/Logo';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
  },
});
