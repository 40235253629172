// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import ContributionItemModel from '../../model/contribution-item-model';
import ContributionItemDTO from '../dto/contribution-item-dto';
import dtoSchema from '../schemas/contribution-item-schema';

export default abstract class ContributionItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<ContributionItemDTO>) {
    super();
    if (data) {
      this.contributionCategorySelectionId = data.contributionCategorySelectionId ?? undefined;
      this.contributionCategorySelectionOrganisationId = data.contributionCategorySelectionOrganisationId ?? undefined;
      this.contributionCategorySelection = data.contributionCategorySelection ?? undefined;
      this.contributionCategory_ContributionValue = data.contributionCategory_ContributionValue ?? undefined;
      this.contributionCategoryFrom = data.contributionCategoryFrom ?? undefined;
      this.contributionCategoryUntil = data.contributionCategoryUntil ?? undefined;
      this.contributionCategoryIsCurrently = data.contributionCategoryIsCurrently ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  contributionCategorySelectionId?: string;
  /**
  * @type {uuid}
  */
  contributionCategorySelectionOrganisationId?: string;
  /**
  */
  contributionCategorySelection?: string;
  /**
  * @type {double}
  */
  contributionCategory_ContributionValue?: number;
  /**
  * @type {date-time}
  */
  contributionCategoryFrom?: string;
  /**
  * @type {date-time}
  */
  contributionCategoryUntil?: string;
  /**
  */
  contributionCategoryIsCurrently?: boolean;

  static toModel(dto: DeepPartial<ContributionItemDTO>): ContributionItemModel;
  static toModel(dto: DeepPartial<ContributionItemDTO> | undefined | null): ContributionItemModel | undefined;
  static toModel(dto: DeepPartial<ContributionItemDTO> | undefined | null): ContributionItemModel | undefined {
    return dto ? new ContributionItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<ContributionItemModel> | ContributionItemModel): ContributionItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      contributionCategorySelectionId: unwrapped.contributionCategorySelectionId,
      contributionCategorySelectionOrganisationId: unwrapped.contributionCategorySelectionOrganisationId,
      contributionCategorySelection: unwrapped.contributionCategorySelection,
      contributionCategory_ContributionValue: unwrapped.contributionCategory_ContributionValue,
      contributionCategoryFrom: unwrapped.contributionCategoryFrom,
      contributionCategoryUntil: unwrapped.contributionCategoryUntil,
      contributionCategoryIsCurrently: unwrapped.contributionCategoryIsCurrently,
    } as ContributionItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for ContributionItemModel, can not map to ContributionItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
