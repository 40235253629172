// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContributionCategorySchema from './contribution-category-schema';
import ContributionItemSchema from './contribution-item-schema';

const schema = {
  $id: '/contribution-schema',
  type: 'object',
  required: ['orderNr', 'taxFreeAmountConfederation', 'taxFreeAmountCanton', 'ahv_FreeAmount', 'ahv_MinorWage', 'ahv_EmployeeCompensation', 'alv_EmployeeCompensation'],
  properties: {
    id: {
      type: 'string',
      format: 'uuid',
    },
    name: {
      type: 'string',
      nullable: true,
    },
    genericName: {
      type: 'string',
      nullable: true,
    },
    shortName: {
      type: 'string',
      nullable: true,
    },
    exportName: {
      type: 'string',
      nullable: true,
    },
    orderNr: {
      type: 'integer',
    },
    region: {
      type: 'string',
      nullable: true,
    },
    country: {
      type: 'string',
      nullable: true,
    },
    address: {
      type: 'string',
      nullable: true,
    },
    address2: {
      type: 'string',
      nullable: true,
    },
    zip: {
      type: 'string',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    phone: {
      type: 'string',
      nullable: true,
    },
    website: {
      type: 'string',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    district: {
      type: 'string',
      nullable: true,
    },
    departmentID: {
      type: 'string',
      format: 'uuid',
    },
    isDepartment: {
      type: 'boolean',
    },
    compensationPeriodBegin: {
      type: 'string',
      format: 'date-time',
    },
    compensationPeriodEnd: {
      type: 'string',
      format: 'date-time',
    },
    isAssociation: {
      type: 'boolean',
    },
    associationName: {
      type: 'string',
      nullable: true,
    },
    associationAddress: {
      type: 'string',
      nullable: true,
    },
    associationAddress2: {
      type: 'string',
      nullable: true,
    },
    associationZip: {
      type: 'string',
      nullable: true,
    },
    associationCity: {
      type: 'string',
      nullable: true,
    },
    associationMail: {
      type: 'string',
      nullable: true,
    },
    category: {
      type: 'string',
      nullable: true,
    },
    contributionCategory: {
      type: 'string',
      nullable: true,
    },
    dtaBankname: {
      type: 'string',
      nullable: true,
    },
    dtaIban: {
      type: 'string',
      nullable: true,
    },
    dtaBankClearing: {
      type: 'string',
      nullable: true,
    },
    dtaPaymentReason: {
      type: 'string',
      nullable: true,
    },
    isLocked: {
      type: 'boolean',
    },
    messageCredit: {
      type: 'number',
      nullable: true,
    },
    instructionsOnLegalRemedies: {
      type: 'string',
      nullable: true,
    },
    isPublicVisible: {
      type: 'boolean',
    },
    taxFreeAmountConfederation: {
      type: 'number',
    },
    taxFreeAmountCanton: {
      type: 'number',
    },
    ahv_FreeAmount: {
      type: 'number',
    },
    retirementFundsNumber: {
      type: 'string',
      nullable: true,
    },
    inviteMail_Stab: {
      type: 'string',
      nullable: true,
    },
    inviteMail_Teilnehmer: {
      type: 'string',
      nullable: true,
    },
    inviteMail_Kdt: {
      type: 'string',
      nullable: true,
    },
    inviteMailSenderMail: {
      type: 'string',
      nullable: true,
    },
    inviteMailSenderName: {
      type: 'string',
      nullable: true,
    },
    ahv_MinorWage: {
      type: 'number',
    },
    wageStatement_Signature: {
      type: 'string',
      nullable: true,
    },
    ahv_EmployeeCompensation: {
      type: 'number',
    },
    alv_EmployeeCompensation: {
      type: 'number',
    },
    language: {
      type: 'string',
      nullable: true,
    },
    categoryItem: {
      ...ContributionCategorySchema,
    },
    items: {
      type: 'array',
      nullable: true,
      items: { ...ContributionItemSchema },
    },
  },
} as IJSONSchema;

export default schema;
