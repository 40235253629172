// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import FupExerciseSettingListModel from '../../model/fup-exercise-setting-list-model';
import FupExerciseSettingListDTO from '../dto/fup-exercise-setting-list-dto';
import dtoSchema from '../schemas/fup-exercise-setting-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class FupExerciseSettingListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<FupExerciseSettingListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.organisationId = data.organisationId ?? undefined;
      this.numberOfExecutions = data.numberOfExecutions;
      this.title = data.title ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {uuid}
  */
  organisationId?: string;
  /**
  * @type {int32}
  */
  numberOfExecutions?: number;
  /**
  */
  title?: string;

  static toModel(dto: DeepPartial<FupExerciseSettingListDTO>): FupExerciseSettingListModel;
  static toModel(dto: DeepPartial<FupExerciseSettingListDTO> | undefined | null): FupExerciseSettingListModel | undefined;
  static toModel(dto: DeepPartial<FupExerciseSettingListDTO> | undefined | null): FupExerciseSettingListModel | undefined {
    return dto ? new FupExerciseSettingListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<FupExerciseSettingListModel> | FupExerciseSettingListModel): FupExerciseSettingListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      organisationId: unwrapped.organisationId,
      numberOfExecutions: unwrapped.numberOfExecutions,
      title: unwrapped.title,
    } as FupExerciseSettingListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for FupExerciseSettingListModel, can not map to FupExerciseSettingListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
