// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import FupOrganisationListModel from '../../model/fup-organisation-list-model';
import FupOrganisationListDTO from '../dto/fup-organisation-list-dto';
import dtoSchema from '../schemas/fup-organisation-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class FupOrganisationListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<FupOrganisationListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.name = data.name ?? undefined;
      this.shortName = data.shortName ?? undefined;
      this.culture = data.culture ?? undefined;
      this.languageIso2 = data.languageIso2 ?? undefined;
      this.district = data.district ?? undefined;
      this.region = data.region ?? undefined;
      this.country = data.country ?? undefined;
      this.category = data.category ?? undefined;
      this.orderNumber = data.orderNumber ?? undefined;
      this.isPublicVisible = data.isPublicVisible ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  name?: string;
  /**
  */
  shortName?: string;
  /**
  */
  culture?: string;
  /**
  */
  languageIso2?: string;
  /**
  */
  district?: string;
  /**
  */
  region?: string;
  /**
  */
  country?: string;
  /**
  */
  category?: string;
  /**
  * @type {int32}
  */
  orderNumber?: number;
  /**
  */
  isPublicVisible?: boolean;

  static toModel(dto: DeepPartial<FupOrganisationListDTO>): FupOrganisationListModel;
  static toModel(dto: DeepPartial<FupOrganisationListDTO> | undefined | null): FupOrganisationListModel | undefined;
  static toModel(dto: DeepPartial<FupOrganisationListDTO> | undefined | null): FupOrganisationListModel | undefined {
    return dto ? new FupOrganisationListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<FupOrganisationListModel> | FupOrganisationListModel): FupOrganisationListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      name: unwrapped.name,
      shortName: unwrapped.shortName,
      culture: unwrapped.culture,
      languageIso2: unwrapped.languageIso2,
      district: unwrapped.district,
      region: unwrapped.region,
      country: unwrapped.country,
      category: unwrapped.category,
      orderNumber: unwrapped.orderNumber,
      isPublicVisible: unwrapped.isPublicVisible,
    } as FupOrganisationListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for FupOrganisationListModel, can not map to FupOrganisationListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
