// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import FupPersonListModel from '../../model/fup-person-list-model';
import FupPersonListDTO from '../dto/fup-person-list-dto';
import dtoSchema from '../schemas/fup-person-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class FupPersonListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<FupPersonListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.firstName = data.firstName ?? undefined;
      this.lastName = data.lastName ?? undefined;
      this.organisationId = data.organisationId ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  */
  firstName?: string;
  /**
  */
  lastName?: string;
  /**
  * @type {uuid}
  */
  organisationId?: string;

  static toModel(dto: DeepPartial<FupPersonListDTO>): FupPersonListModel;
  static toModel(dto: DeepPartial<FupPersonListDTO> | undefined | null): FupPersonListModel | undefined;
  static toModel(dto: DeepPartial<FupPersonListDTO> | undefined | null): FupPersonListModel | undefined {
    return dto ? new FupPersonListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<FupPersonListModel> | FupPersonListModel): FupPersonListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      firstName: unwrapped.firstName,
      lastName: unwrapped.lastName,
      organisationId: unwrapped.organisationId,
    } as FupPersonListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for FupPersonListModel, can not map to FupPersonListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
