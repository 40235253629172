// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WebOperationListModel from '../../model/web-operation-list-model';
import WebOperationListDTO from '../dto/web-operation-list-dto';
import dtoSchema from '../schemas/web-operation-list-schema';

export default abstract class WebOperationListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WebOperationListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.alertedOn = data.alertedOn;
      this.description = data.description ?? undefined;
      this.link = data.link ?? undefined;
      this.operationNumber = data.operationNumber;
      this.organisationId = data.organisationId;
      this.organisationName = data.organisationName ?? undefined;
      this.location = data.location ?? undefined;
      this.damageType = data.damageType ?? undefined;
      this.numberAdfOnDuty = data.numberAdfOnDuty;
      this.totalStaffHours = data.totalStaffHours;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  * @type {date-time}
  */
  alertedOn?: string;
  /**
  */
  description?: string;
  /**
  */
  link?: string;
  /**
  * @type {int64}
  */
  operationNumber?: number;
  /**
  * @type {uuid}
  */
  organisationId?: string;
  /**
  */
  organisationName?: string;
  /**
  */
  location?: string;
  /**
  */
  damageType?: string;
  /**
  * @type {int32}
  */
  numberAdfOnDuty?: number;
  /**
  * @type {double}
  */
  totalStaffHours?: number;

  static toModel(dto: DeepPartial<WebOperationListDTO>): WebOperationListModel;
  static toModel(dto: DeepPartial<WebOperationListDTO> | undefined | null): WebOperationListModel | undefined;
  static toModel(dto: DeepPartial<WebOperationListDTO> | undefined | null): WebOperationListModel | undefined {
    return dto ? new WebOperationListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WebOperationListModel> | WebOperationListModel): WebOperationListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      alertedOn: unwrapped.alertedOn,
      description: unwrapped.description,
      link: unwrapped.link,
      operationNumber: unwrapped.operationNumber,
      organisationId: unwrapped.organisationId,
      organisationName: unwrapped.organisationName,
      location: unwrapped.location,
      damageType: unwrapped.damageType,
      numberAdfOnDuty: unwrapped.numberAdfOnDuty,
      totalStaffHours: unwrapped.totalStaffHours,
    } as WebOperationListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WebOperationListModel, can not map to WebOperationListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
