// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetOrganisationByIdRequestModel from '../../model/get-organisation-by-id-request-model';
import GetOrganisationByIdRequestDTO from '../dto/get-organisation-by-id-request-dto';
import dtoSchema from '../schemas/get-organisation-by-id-request-schema';

export default abstract class GetOrganisationByIdRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetOrganisationByIdRequestDTO>) {
    super();
    if (data) {
      this.id = data.id;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;

  static toModel(dto: DeepPartial<GetOrganisationByIdRequestDTO>): GetOrganisationByIdRequestModel;
  static toModel(dto: DeepPartial<GetOrganisationByIdRequestDTO> | undefined | null): GetOrganisationByIdRequestModel | undefined;
  static toModel(dto: DeepPartial<GetOrganisationByIdRequestDTO> | undefined | null): GetOrganisationByIdRequestModel | undefined {
    return dto ? new GetOrganisationByIdRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetOrganisationByIdRequestModel> | GetOrganisationByIdRequestModel): GetOrganisationByIdRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
    } as GetOrganisationByIdRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetOrganisationByIdRequestModel, can not map to GetOrganisationByIdRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
