// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import WebAppointmentListModel from '../../model/web-appointment-list-model';
import WebAppointmentListDTO from '../dto/web-appointment-list-dto';
import dtoSchema from '../schemas/web-appointment-list-schema';

export default abstract class WebAppointmentListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<WebAppointmentListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.durationHoursPerDay = data.durationHoursPerDay;
      this.durationDays = data.durationDays;
      this.type = data.type ?? undefined;
      this.title = data.title ?? undefined;
      this.location = data.location ?? undefined;
      this.meetingPoint = data.meetingPoint ?? undefined;
      this.link = data.link ?? undefined;
      this.participants = data.participants ?? undefined;
    }
  }
  /**
  * @type {uuid}
  */
  id?: string;
  /**
  * @type {date-time}
  */
  startDate?: string;
  /**
  * @type {date-time}
  */
  endDate?: string;
  /**
  * @type {double}
  */
  durationHoursPerDay?: number;
  /**
  * @type {double}
  */
  durationDays?: number;
  /**
  */
  type?: string;
  /**
  */
  title?: string;
  /**
  */
  location?: string;
  /**
  */
  meetingPoint?: string;
  /**
  */
  link?: string;
  /**
  */
  participants?: string;

  static toModel(dto: DeepPartial<WebAppointmentListDTO>): WebAppointmentListModel;
  static toModel(dto: DeepPartial<WebAppointmentListDTO> | undefined | null): WebAppointmentListModel | undefined;
  static toModel(dto: DeepPartial<WebAppointmentListDTO> | undefined | null): WebAppointmentListModel | undefined {
    return dto ? new WebAppointmentListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<WebAppointmentListModel> | WebAppointmentListModel): WebAppointmentListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      startDate: unwrapped.startDate,
      endDate: unwrapped.endDate,
      durationHoursPerDay: unwrapped.durationHoursPerDay,
      durationDays: unwrapped.durationDays,
      type: unwrapped.type,
      title: unwrapped.title,
      location: unwrapped.location,
      meetingPoint: unwrapped.meetingPoint,
      link: unwrapped.link,
      participants: unwrapped.participants,
    } as WebAppointmentListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for WebAppointmentListModel, can not map to WebAppointmentListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
