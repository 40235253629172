// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetFupExerciseSettingsRequestModel from '../../model/get-fup-exercise-settings-request-model';
import GetFupOrganisationsRequestModel from '../../model/get-fup-organisations-request-model';
import GetFupOrganisationLogoRequestModel from '../../model/get-fup-organisation-logo-request-model';
import GetFupPersonsRequestModel from '../../model/get-fup-persons-request-model';
import LastLoginCheckRequestModel from '../../model/last-login-check-request-model';
import GetFupUsersRequestModel from '../../model/get-fup-users-request-model';
import FupExerciseSettingListPagingResultModel from '../../model/fup-exercise-setting-list-paging-result-model';
import FupOrganisationListPagingResultModel from '../../model/fup-organisation-list-paging-result-model';
import FupPersonListPagingResultModel from '../../model/fup-person-list-paging-result-model';
import FupUserListModel from '../../model/fup-user-list-model';
import FupUserListPagingResultModel from '../../model/fup-user-list-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getFupExerciseSettings(request: GetFupExerciseSettingsRequestModel, config?: RequestConfig) {
    const requestDTO = GetFupExerciseSettingsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-fup-exercise-settings'] ?? 'Fup/ExerciseSettings';
    const response = await service.get<any>(endpointPath, {
      query: {
        organisationId: requestDTO.organisationId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FupExerciseSettingListPagingResultModel.toModel(dto));
  },
  /**  */
  async getFupOrganisations(request: GetFupOrganisationsRequestModel, config?: RequestConfig) {
    const requestDTO = GetFupOrganisationsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-fup-organisations'] ?? 'Fup/Organisations';
    const response = await service.get<any>(endpointPath, {
      query: {
        organisationId: requestDTO.organisationId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FupOrganisationListPagingResultModel.toModel(dto));
  },
  /**  */
  async getFupOrganisationLogo(request: GetFupOrganisationLogoRequestModel, config?: RequestConfig) {
    const requestDTO = GetFupOrganisationLogoRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-fup-organisation-logo'] ?? 'Fup/Organisations/{id}/Logo';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
  },
  /**  */
  async getFupPersons(request: GetFupPersonsRequestModel, config?: RequestConfig) {
    const requestDTO = GetFupPersonsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-fup-persons'] ?? 'Fup/Person';
    const response = await service.get<any>(endpointPath, {
      query: {
        organisationId: requestDTO.organisationId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FupPersonListPagingResultModel.toModel(dto));
  },
  /**  */
  async lastLoginCheck(request: LastLoginCheckRequestModel, config?: RequestConfig) {
    const requestDTO = LastLoginCheckRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-last-login-check'] ?? 'Fup/Users/{id}/Organisations/{organisationId}/LastLoginCheck';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    endpointPath = endpointPath.replace(/\{organisationId\}/g, `${requestDTO.organisationId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FupUserListModel.toModel(dto));
  },
  /**  */
  async getFupUsers(request: GetFupUsersRequestModel, config?: RequestConfig) {
    const requestDTO = GetFupUsersRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-fup-users'] ?? 'Fup/Users';
    const response = await service.get<any>(endpointPath, {
      query: {
        userId: requestDTO.userId,
        organisationId: requestDTO.organisationId,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(FupUserListPagingResultModel.toModel(dto));
  },
});
